import { createSlice } from "@reduxjs/toolkit";
import planMasse from "Assets/planMasse.png";

export const sitesSlice = createSlice({
  name: "sites",
  initialState: {
    selectedSite: {
      baseImage: { url: planMasse, width: 7087, height: 3307 },
    },
  },
  reducers: {
    toggleMode: (state) => {},
  },
});

export const { toggleMode } = sitesSlice.actions;

export default sitesSlice.reducer;
