import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Typography, Button, Paper, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import MapViewer from "./containers/MapViewer";
import PositionPad from "./containers/PositionPad";
import ModelViewer from "./containers/ModelViewer";
import ModeButton from "./containers/ModeButton";

import PageLayout from "Components/PageLayout";

const useStyles = makeStyles((theme) => ({
  viewerContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
  },
  positionPadContainer: {
    position: "fixed",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
  },
  modeButtonContainer: {
    position: "fixed",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default function Home() {
  const classes = useStyles();
  const [padPosition, setPadPosition] = useState({ x: 0.5, y: 0.5 });
  const [mode3d, setMode3d] = useState(false);

  //initial state
  const baseImage = useSelector((state) => state.sites.selectedSite.baseImage);

  function handlePadPositionMove(e) {
    setPadPosition(e);
  }

  function switchMode() {
    setMode3d((mode3d) => !mode3d);
  }
  return (
    <PageLayout>
      <div className={classes.modeButtonContainer}>
        <ModeButton mode3d={mode3d} onClick={switchMode} />
      </div>
      <div className={classes.viewerContainer}>
        {mode3d ? <ModelViewer position={padPosition} /> : <MapViewer />}
      </div>
      {mode3d && (
        <Paper elevation={6} className={classes.positionPadContainer}>
          <PositionPad
            img={baseImage}
            x={padPosition.x}
            y={padPosition.y}
            onMove={handlePadPositionMove}
          />
        </Paper>
      )}
    </PageLayout>
  );
}
