import blueprint from "Assets/blueprint.png";
import layer1 from "Assets/layer1.png";

const map = {
  name: "Exemple de carte",
  layersId: ["blueprint", "layer1"],
  layers: {
    blueprint: { url: blueprint, width: 1970, height: 1624 },
    layer1: { url: layer1, width: 700, height: 700 },
  },
};

export default map;
