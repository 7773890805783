// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "555f0cb2-4094-45b2-b0c7-24e333fb64e6",
    authority:
      "https://capla360.b2clogin.com/capla360.onmicrosoft.com/B2C_1_signin-signup-1",
    knownAuthorities: ["capla360.b2clogin.com"],
    //redirectUri: "http://localhost:3000/auth",
    redirectUri: "https://www.capla360.com/auth",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
