import { configureStore } from "@reduxjs/toolkit";
import mapsReducer from "Features/maps/mapsSlice";
import layersReducer from "Features/layers/layersSlice";
import uiReducer from "Features/ui/uiSlice";
import markersReducer from "Features/markers/markersSlice";
import sitesReducer from "Features/sites/sitesSlice";
import objectsReducer from "Features/objects/objectsSlice";

export default configureStore({
  reducer: {
    layers: layersReducer,
    ui: uiReducer,
    markers: markersReducer,
    maps: mapsReducer,
    objects: objectsReducer,
    sites: sitesReducer,
  },
});
