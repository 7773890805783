import { createSlice } from "@reduxjs/toolkit";
import mur from "Assets/mur.png";
import poutre from "Assets/poutre.png";

export const objectsSlice = createSlice({
  name: "objects",
  initialState: {
    ids: ["mur", "poutre"],
    items: {
      mur: { url: mur },
      poutre: { url: poutre },
    },
  },
  reducers: {
    toggleMode: (state) => {},
  },
});

export const { toggleMode } = objectsSlice.actions;

export default objectsSlice.reducer;
