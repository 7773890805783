import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Avatar, Typography } from "@material-ui/core";

import clsx from "clsx";

import { toggleMarker } from "Features/markers/markersSlice";

const useStyles = makeStyles((theme) => ({
  markersContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  markersList: {
    display: "flex",
  },
  marker: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  selectedMarker: {
    background: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

export default function Markers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const markers = useSelector((state) => state.markers.items);
  const selectedMarkerId = useSelector(
    (state) => state.markers.selectedMarkerId
  );

  function handleMarkerClick(markerId) {
    dispatch(toggleMarker(markerId));
  }

  return (
    <div className={classes.markersContainer}>
      <Typography> Repères :</Typography>
      <div className={classes.markersList}>
        {markers.map(({ id }) => (
          <Avatar
            onClick={() => handleMarkerClick(id)}
            className={clsx(
              classes.marker,
              id === selectedMarkerId && classes.selectedMarker
            )}
            key={id}
          >
            {id}
          </Avatar>
        ))}
      </div>
    </div>
  );
}
