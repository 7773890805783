import React from "react";
import logo from "./logo100.png";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
  },
}));

export default function Logo() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={logo} height={30} />
    </div>
  );
}
