import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import image from "Assets/blueprint.png";
import layer1 from "Assets/layer1.png";
import layer2 from "Assets/layer2.png";
import layer3 from "Assets/layer3.png";
import grid from "Assets/grid.png";
import {
  transformLayer2BaseOnLayer1,
  transformedLayersBaseOnReferenceLayer,
} from "Utils";

export const updateTransformedReferenceLayer = createAsyncThunk(
  "layers/updateTransformedReferenceLayer",
  async ({ layer1, layer2, markers }) => {
    const transformedLayer = await transformLayer2BaseOnLayer1(
      layer1,
      layer2,
      markers
    );
    return transformedLayer;
  }
);

export const layerize = createAsyncThunk(
  "layers/layerize",
  async ({ layers, markers, referenceLayerId }) => {
    const transformedLayers = transformedLayersBaseOnReferenceLayer(
      layers,
      markers,
      referenceLayerId
    );
    return transformedLayers;
  }
);

export const layersSlice = createSlice({
  name: "layers",
  initialState: {
    items: [
      //{ id: "1", url: image, width: 1970, height: 1624 },
      { id: "layer1", url: layer1, width: 700, height: 700 },
      {
        id: "layer2",
        url: layer2,
        width: 700,
        height: 700,
      },
      {
        id: "layer3",
        url: layer3,
        width: 1000,
        height: 600,
      },
    ],
    selectedLayerId: null,
    referenceLayerId: "layer1",
    transformedReferenceLayer: {
      url: grid,
      width: 700,
      height: 700,
      bBoxTL: [0, 0],
    },
    transformedLayers: {
      ids: [],
      items: {},
    },
    layerableIds: [],
  },
  reducers: {
    addLayer: (state, action) => {
      state.items.push(action.payload);
    },
    removeLayer: (state, action) => {
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== action.payload.layerId),
      };
    },
    selectLayerId: (state, action) => {
      state.selectedLayerId =
        action.payload === state.selectedLayerId ? null : action.payload;
    },
    toggleReference: (state, action) => {
      if (state.referenceLayerId !== action.payload) {
        state.referenceLayerId = action.payload;
      }
    },
    updateLayerableIds: (state, action) => {
      state.layerableIds = action.payload;
    },
    switchTransformedLayerDisplay: (state, action) => {
      state.transformedLayers.items[action.payload].display = !state
        .transformedLayers.items[action.payload].display;
    },
  },
  extraReducers: {
    [updateTransformedReferenceLayer.fulfilled]: (state, action) => {
      state.transformedReferenceLayer = action.payload;
    },
    [layerize.fulfilled]: (state, action) => {
      state.transformedLayers = action.payload;
    },
  },
});

export const {
  addLayer,
  removeLayer,
  selectLayerId,
  toggleReference,
  updateLayerableIds,
  switchTransformedLayerDisplay,
} = layersSlice.actions;

export default layersSlice.reducer;
