import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { makeStyles, Typography } from "@material-ui/core";
import { ArrowDownward as DownloadIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonText: {
    color: "black",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));
export default function LayersDownloadButton() {
  const classes = useStyles();
  const linksRef = useRef();

  const transformedLayers = useSelector(
    (state) => state.layers.transformedLayers
  );

  function handleLinksClick() {
    const links = linksRef.current.children;
    console.log(links.length);
    for (let i = 0; i < links.length; i++) {
      linksRef.current.children[i].click();
    }
  }
  function handleOneLinkClick(e) {
    e.stopPropagation();
  }

  return (
    <div>
      <Button
        startIcon={<DownloadIcon className={classes.icon} />}
        onClick={handleLinksClick}
      >
        <div ref={linksRef}>
          {transformedLayers.ids.map((layerId) => {
            return (
              <a
                key={layerId}
                href={transformedLayers.items[layerId].url}
                download
                onClick={handleOneLinkClick}
              />
            );
          })}
        </div>
        <Typography className={classes.buttonText}>
          Télécharger les images
        </Typography>
      </Button>
    </div>
  );
}
