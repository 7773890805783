import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core";

import FileUploader from "Components/FileUploader";
import LayersCollection from "./components/LayersCollection";

import {
  addLayer,
  removeLayer,
  selectLayerId,
} from "Features/layers/layersSlice";
import { nanoid } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
  layersManagerContainer: {
    width: 240,
  },
}));

export default function LayersManager() {
  const classes = useStyles();
  const layers = useSelector((state) => state.layers.items);
  const selectedLayerId = useSelector((state) => state.layers.selectedLayerId);
  const dispatch = useDispatch();

  function handleAddLayer(url) {
    console.log(url);
  }
  function handleRemoveLayer(layerId) {
    dispatch(removeLayer({ layerId }));
  }
  function handleAddLayer(url) {
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      dispatch(addLayer({ id: nanoid(), width, height, url }));
    };
    img.src = url;
  }
  function handleLayerClick(layerId) {
    dispatch(selectLayerId(layerId));
  }

  return (
    <div className={classes.layersManagerContainer}>
      <LayersCollection
        layers={layers}
        selectedLayerId={selectedLayerId}
        onLayerDeleted={handleRemoveLayer}
        onLayerClick={handleLayerClick}
      />
      <FileUploader onFileUpload={handleAddLayer} />
    </div>
  );
}
