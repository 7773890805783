import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link, Breadcrumbs, Typography } from "@material-ui/core";

import ToolLogo from "./ToolLogo";

const breadcrumbNameMap = {
  "/superpose": "Superpose",
};

export default function Path() {
  const location = useLocation();

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

  const pathnames = location.pathname.split("/").filter((x) => x);
  const toolName = pathnames.slice(-1)[0];

  const isHomePage = pathnames.length === 0;

  return (
    !isHomePage && (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to="/">
            Home
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {/* {breadcrumbNameMap[to]} */}
              </Typography>
            ) : (
              <LinkRouter color="inherit" to={to} key={to}>
                {breadcrumbNameMap[to]}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
        <ToolLogo toolName={toolName} />
      </>
    )
  );
}
