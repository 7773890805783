import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import L from "leaflet";
import PageLayout from "Components/PageLayout";

import layer1 from "Assets/layer1.png";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: 500,
    height: 400,
    background: "yellow",
  },
}));

export default function Test() {
  const classes = useStyles();
  const [angle, setAngle] = useState(5);

  const mapRef = useRef();
  const mapContainerRef = useRef();

  const imageHeight = 700;
  const imageWidth = 700;

  const [bb, setBb] = useState([
    [0, 0],
    [imageWidth, imageHeight],
  ]);

  function rotate() {
    setAngle((angle) => angle + 10);
  }

  // compute BB
  function transformedBoudingBox(width, height, x, y, scale, angle_deg) {
    const angle = (angle_deg * Math.PI) / 180;
    const [ul, dr] = bb; // ul : upper left, dr : down right
    const OA = scale * Math.sqrt(x * x + y * y);
    const OB = scale * Math.sqrt((width - x) * (width - x) + y * y);
    const OC =
      scale *
      Math.sqrt((width - x) * (width - x) + (height - y) * (height - y));
    const OD = scale * Math.sqrt(x * x + (height - y) * (height - y));
    const A = {};
    const B = {};
    const C = {};
    const D = {};

    A.x = x - OA * Math.sin(Math.atan(x / y) - angle);
    A.y = y - OA * Math.cos(Math.atan(x / y) - angle);
    B.x = x + OB * Math.cos(Math.atan(y / (width - x)) - angle);
    B.y = y - OB * Math.sin(Math.atan(y / (width - x)) - angle);
    C.x = x + OC * Math.cos(Math.atan((height - y) / (width - x)) + angle);
    C.y = y + OC * Math.sin(Math.atan((height - y) / (width - x)) + angle);
    D.x = x - OD * Math.sin(Math.atan(x / (height - y)) + angle);
    D.y = y + OD * Math.cos(Math.atan(x / (height - y)) + angle);

    return [
      [height - A.y, A.x],
      [height - B.y, B.x],
      [height - C.y, C.x],
      [height - D.y, D.x],
      [height - A.y, A.x],
    ];
  }

  // Leaflet • display map
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -5,
        zoomSnap: 0.5,
        attributionControl: false,
        zoomControl: false,
      });
    }
    const bounds = [
      [0, 0],
      [imageHeight, imageWidth],
    ];
    //remove all layers
    mapRef.current.eachLayer((layer) => mapRef.current.removeLayer(layer));

    // add layer
    const image = L.imageOverlay(layer1, bounds).addTo(mapRef.current);
    mapRef.current.fitBounds(bounds);
  }, []);

  // Leaflet • plot boundingBox
  useEffect(() => {
    // delete boundingBox
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Polyline) {
        mapRef.current.removeLayer(layer);
      }
    });
    // plot boundingbox
    L.polyline(transformedBoudingBox(700, 700, 175, 175, 1.2, angle), {
      weight: 2,
    }).addTo(mapRef.current);
  }, [angle]);

  return (
    <PageLayout>
      <Typography> {`Angle : ${angle}`}</Typography>
      <Button variant="contained" onClick={rotate}>
        Tourne
      </Button>
      <div className={classes.mapContainer} ref={mapContainerRef} />
    </PageLayout>
  );
}
