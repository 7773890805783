import React from "react";
import { Done } from "@material-ui/icons";
import { makeStyles, IconButton, Typography } from "@material-ui/core";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  iconButton: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  checked: {
    background: theme.palette.primary.main,
  },
  text: {
    fontWeight: "bold",
  },
}));

export default function IsReferenceButton({
  checked = true,
  onClick = () => console.log("clicked"),
}) {
  const classes = useStyles();

  function handleClick(e) {
    e.stopPropagation();
    onClick();
  }
  return (
    <div className={classes.buttonContainer}>
      <IconButton
        size="small"
        className={clsx(classes.iconButton, checked && classes.checked)}
        onClick={handleClick}
      >
        <Done />
      </IconButton>
      {checked && <Typography className={classes.text}>Référence</Typography>}
    </div>
  );
}
