import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Button, Link } from "@material-ui/core";
import Konva from "konva";

import {
  getPointsForLayeringComputation,
  angleToVerticalAB_deg,
  distance,
} from "Utils";

const useStyles = makeStyles((theme) => ({
  canvasContainer: {
    width: "100%",
    height: "100%",
    background: "yellow",
  },
  canvas: {
    background: "white",
  },
}));

Konva.hitOnDragEnabled = true;

export default function LayeringCanvas() {
  //styles
  const classes = useStyles();

  //component state
  const [href, setHref] = useState();
  //states
  const layers = useSelector((state) => state.layers.items);
  const layer1 = layers.find(({ id }) => id === "layer1");
  const layer2 = layers.find(({ id }) => id === "layer2");
  const markers = useSelector((state) => state.markers.items);

  //points & rotations & scale
  const { mA1, mB1, mA2, mB2 } = getPointsForLayeringComputation(
    "layer1",
    "layer2",
    markers
  );
  const layer1Rotation = angleToVerticalAB_deg(mA1, mB1);
  const layer2Rotation = angleToVerticalAB_deg(mA2, mB2);
  const ma1X = mA1.x * layer1.width,
    ma1Y = mA1.y * layer1.height;
  const ma2X = mA2.x * layer2.width,
    ma2Y = mA2.y * layer2.height;
  const ma1 = { x: ma1X, y: ma1Y },
    ma2 = { x: ma2X, y: ma2Y };

  const mb1X = mB1.x * layer1.width,
    mb1Y = mB1.y * layer1.height;
  const mb2X = mB2.x * layer2.width,
    mb2Y = mB2.y * layer2.height;
  const mb1 = { x: mb1X, y: mb1Y },
    mb2 = { x: mb2X, y: mb2Y };
  const scale2to1 = distance(ma1, mb1) / distance(ma2, mb2);

  const canvasRef = useRef();
  const canvasContainerRef = useRef();
  const stageRef = useRef();

  useEffect(() => {
    const canvasContainer = canvasContainerRef.current;
    const height = canvasContainer.offsetHeight;
    const width = canvasContainer.offsetWidth;

    // Konva init
    const stage = new Konva.Stage({
      container: canvasRef.current,
      width,
      height,
      draggable: true,
    });
    stageRef.current = stage;
    const group = new Konva.Group();
    var layer = new Konva.Layer();
    layer.add(group);
    stage.add(layer);

    // Zoom
    var scaleBy = 1.02;
    stage.on("wheel", (e) => {
      e.evt.preventDefault();
      var oldScale = stage.scaleX();

      var pointer = stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    });

    function drawImage(img, offsetX, offsetY, rotation, scale) {
      console.log(img);
      var imageObj = new Image();
      imageObj.onload = function () {
        var newImage = new Konva.Image({
          x: 0,
          y: 0,
          image: imageObj,
          width: img.width,
          height: img.height,
          scaleX: scale,
          scaleY: scale,
        });
        newImage.offsetX(offsetX);
        newImage.offsetY(offsetY);
        newImage.rotate(rotation);
        layer.add(newImage);
        layer.batchDraw();

        //add to group
        group.add(newImage);
      };
      imageObj.src = img.url;
      // add the shape to the layer
    }
    drawImage(
      layer1,
      mA1.x * layer1.width,
      mA1.y * layer1.height,
      layer1Rotation,
      1
    );
    drawImage(
      layer2,
      mA2.x * layer2.width,
      mA2.y * layer2.height,
      layer2Rotation,
      scale2to1
    );
  }, []);

  function getImage() {
    //group computation
    console.log(stageRef.current.toDataURL());
  }
  return (
    <div ref={canvasContainerRef} className={classes.canvasContainer}>
      <Button onClick={getImage}>getImage</Button>
      <div id="container" className={classes.canvas} ref={canvasRef} />
    </div>
  );
}
