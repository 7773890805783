import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: { layeringMode: false },
  reducers: {
    toggleMode: (state) => {
      state.layeringMode = !state.layeringMode;
    },
  },
});

export const { toggleMode } = uiSlice.actions;

export default uiSlice.reducer;
