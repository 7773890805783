import { createSlice } from "@reduxjs/toolkit";

export const markersSlice = createSlice({
  name: "markers",
  initialState: {
    selectedMarkerId: null,
    items: [
      {
        id: "1",
        positions: [
          //{ layerId: "1", x: 0.0, y: 0.0 },
          { layerId: "layer1", x: 0.25, y: 0.25 },
          { layerId: "layer2", x: 0.25, y: 0.5 },
          { layerId: "layer3", x: 0.25, y: 0.25 },
        ],
      },
      {
        id: "2",
        positions: [
          //{ layerId: "1", x: 0.5, y: 0.5 },
          { layerId: "layer1", x: 0.5, y: 0.5 },
          { layerId: "layer2", x: 0.25, y: 0.75 },
          { layerId: "layer3", x: 0.5, y: 0.75 },
        ],
      },
    ],
  },
  reducers: {
    addMarker: (state, action) => {
      let newMarker = true;
      const { markerId, layerId, x, y } = action.payload;
      console.log("Adding marker :", markerId, layerId, x, y);
      state.items.forEach(({ id, positions }) => {
        if (id === markerId) {
          newMarker = false;
          let newPositions = positions.filter(
            (position) => position.layerId !== layerId
          );
          newPositions = [...newPositions, { layerId, x, y }];
          state.items = state.items.filter((item) => item.id !== markerId);
          state.items.push({ id: markerId, positions: newPositions });
        }
      });
      if (newMarker) {
        state.items = [
          ...state.items,
          { id: markerId, positions: [{ layerId, x, y }] },
        ];
      }
    },
    removeMarker: (state, action) => {
      const { selectedLayerId, selectedMarkerId } = action.payload;
      const items = state.items;
      const marker = state.items.find(
        (marker) => marker.id === selectedMarkerId
      );
      marker.positions = marker.positions.filter(
        (position) => position.layerId !== selectedLayerId
      );
      const newItems = items.filter((marker) => marker.id !== selectedMarkerId);
      state.items = [...newItems, marker];
    },
    toggleMarker: (state, action) => {
      state.selectedMarkerId =
        state.selectedMarkerId === action.payload ? null : action.payload;
    },
  },
});

export const { addMarker, removeMarker, toggleMarker } = markersSlice.actions;

export default markersSlice.reducer;
