import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import { CallMade, CallReceived, AllOut } from "@material-ui/icons";

import clsx from "clsx";
import L from "leaflet";

import "./positionPad.css";
import positionMarkerUrl from "./positionMarker.png";
import map from "Data/defaultMap";

const useStyles = makeStyles((theme) => ({
  padContainer: {
    background: "white",
    position: "relative",
  },
  small: { height: 150, width: 150 },
  large: { height: 400, width: 400 },

  iconButtonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  mapContainer: {
    background: "white",
    width: "100%",
    height: "100%",
  },
  leafletContainer: {
    background: "white",
    width: "100%",
    height: "100%",
    border: "solid 1px black",
  },
  button: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2000,
  },
  fitBoundsButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 2000,
  },
}));

export default function PositionPad({ img, x, y, onMove }) {
  const classes = useStyles();
  const [isSmall, setIsSmall] = useState(true);
  const [mapDim, setMapDim] = useState({ w: 300, h: 300 });

  // compute map dimension
  const padContainerRef = useRef();
  useEffect(() => {
    const { width, height } = padContainerRef.current.getBoundingClientRect();
    setMapDim({ w: width, h: height });
  }, [mapDim.w, mapDim.h, isSmall]);

  return (
    <div
      ref={padContainerRef}
      className={clsx(classes.padContainer, {
        [classes.small]: isSmall,
        [classes.large]: !isSmall,
      })}
    >
      <div className={classes.iconButtonContainer}>
        <IconButton onClick={() => setIsSmall((isSmall) => !isSmall)}>
          {isSmall ? <CallMade /> : <CallReceived />}
        </IconButton>
      </div>
      <div className={classes.mapContainer}>
        <Map img={img} x={x} y={y} onMove={onMove} isSmall={isSmall} />
      </div>
    </div>
  );
}

function Map({ img, x, y, onMove, isSmall }) {
  const classes = useStyles();
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const { url, height, width } = img;

  const [position, setPosition] = useState();

  // leaflet map
  useEffect(() => {
    // initial map
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -6,
        maxZoom: 16,
        zoomSnap: 0.5,
        attributionControl: false,
        zoomControl: false,
      });
    }
    const bounds = [
      [0, 0],
      [height, width],
    ];
    const image = L.imageOverlay(url, bounds).addTo(mapRef.current);
    mapRef.current.fitBounds(bounds);

    // position marker
    const icon = new L.icon({
      iconUrl: positionMarkerUrl,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
    });
    const positionMarker = L.marker([y * height, x * width], {
      icon,
      draggable: true,
    }).addTo(mapRef.current);

    const latLng = positionMarker.getLatLng();
    setPosition(latLng);

    function getNewPosition() {
      const latLng = positionMarker.getLatLng();
      const newPosition = { x: latLng.lng / width, y: 1 - latLng.lat / height };
      onMove(newPosition);
    }
    positionMarker.on("drag", getNewPosition);
    positionMarker.on("dragend", () => setPosition(positionMarker.getLatLng()));
  }, []);

  // update map view when isSmall changes
  useEffect(() => {
    if (position) {
      mapRef.current.invalidateSize();
      mapRef.current.panTo(position);
    }
  }, [isSmall]);

  function handleFitBoundsClick() {
    mapRef.current.invalidateSize();
    const bounds = [
      [0, 0],
      [height, width],
    ];
    mapRef.current.fitBounds(bounds);
  }

  //change Map position
  return (
    <div className={classes.leafletContainer} ref={mapContainerRef}>
      <IconButton
        className={classes.fitBoundsButton}
        onClick={handleFitBoundsClick}
      >
        <AllOut />
      </IconButton>
    </div>
  );
}
