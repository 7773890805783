import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "Pages/Home";
import Superpose from "Pages/Superpose";
import Test from "Pages/Test";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/auth" component={Home} />
      <Route exact path="/superpose" component={Superpose} />
      <Route exact path="/test" component={Test} />
    </Switch>
  );
}
