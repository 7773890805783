import React from "react";
import { useSelector } from "react-redux";

import { makeStyles, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import Map from "Components/MapViewer";

const useStyles = makeStyles((theme) => ({
  mapViewerContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9000,
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
  },
}));

export default function MapViewer({ open, onClose }) {
  const classes = useStyles();
  const transformedLayers = useSelector(
    (state) => state.layers.transformedLayers
  );
  return (
    open && (
      <div className={classes.mapViewerContainer}>
        <IconButton className={classes.iconButton} onClick={onClose}>
          <Close />
        </IconButton>
        <Map
          map={{ name: "Superposition des calques", layers: transformedLayers }}
        />
      </div>
    )
  );
}
