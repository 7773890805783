import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { HighlightOff as RemoveIcon } from "@material-ui/icons";
import { removeMarker } from "Features/markers/markersSlice";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));

export default function RemoveMarkerButton() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedLayerId = useSelector((state) => state.layers.selectedLayerId);

  const selectedMarkerId = useSelector(
    (state) => state.markers.selectedMarkerId
  );
  function deleteMarker() {
    dispatch(removeMarker({ selectedLayerId, selectedMarkerId }));
  }

  return (
    <Button
      startIcon={<RemoveIcon className={classes.icon} />}
      onClick={deleteMarker}
      disabled={!selectedMarkerId}
    >
      <Typography
        className={classes.buttonText}
      >{`Retirer le repère ${selectedMarkerId}`}</Typography>
    </Button>
  );
}
