import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, Button, Typography, Link } from "@material-ui/core";

import { ArrowDownward as DownloadIcon } from "@material-ui/icons";
import { HighlightOff as RemoveIcon } from "@material-ui/icons";

import LayersDownloadButton from "./components/LayersDownloadButton";
import RemoveMarkerButton from "./components/RemoveMarkerButton";

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    width: "100%",
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    borderBottom: `solid 1px ${theme.palette.common.borderGrey}`,
    color: theme.palette.primary.main,
    display: "flex",
  },
  buttonText: {
    color: "black",
  },
  links: {
    width: 50,
    height: 50,
    background: theme.palette.primary.main,
  },
}));

export default function Toolbar() {
  const classes = useStyles();

  return (
    <div className={classes.toolbarContainer}>
      <div className={classes.buttonsContainer}>
        <RemoveMarkerButton />
        <LayersDownloadButton />
      </div>
    </div>
  );
}
