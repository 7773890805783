import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { Close } from "@material-ui/icons";

import ImagePreview from "Components/ImagePreview";
import IsReferenceButton from "./IsReferenceButton";
import PreviewModeSwitch from "./PreviewModeSwitch";

import { toggleReference } from "Features/layers/layersSlice";

const useStyles = makeStyles((theme) => ({
  layerPreviewContainer: {
    height: 124,
    position: "relative",
    background: "white",
    marginBottom: theme.spacing(2),
  },
  selectedBorder: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  iconButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(1),
    zIndex: 2,
  },
  button: {
    background: "white",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  checkButtonContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    margin: theme.spacing(1),
  },
}));

export default function LayerPreview({
  onClose,
  onClick,
  layer,
  selected = false,
  isReference = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleIsReferenceClick() {
    dispatch(toggleReference(layer.id));
  }
  return (
    <div
      className={clsx(
        classes.layerPreviewContainer,
        selected && classes.selectedBorder
      )}
      onClick={() => onClick(layer.id)}
    >
      <IconButton
        size="small"
        className={classes.iconButton}
        onClick={() => onClose(layer.id)}
        disableRipple
        classes={{ root: classes.button }}
      >
        <Close />
      </IconButton>
      <ImagePreview src={layer.url} width={"100%"} height={"100%"} />
      <div className={classes.checkButtonContainer}>
        <IsReferenceButton
          checked={isReference}
          onClick={handleIsReferenceClick}
        />
      </div>
    </div>
  );
}
