import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import * as THREE from "three";

import OrbitControls from "three-orbit-controls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import blueprint from "Assets/planMasse.png";

import soldier from "Assets/soldier.glb";
import { Vector3 } from "three";

const useStyles = makeStyles((theme) => ({
  modelContainer: {
    width: "100%",
    height: "100%",
    //background: theme.palette.secondary.main,
  },
}));

export default function ModelViewer({ position }) {
  const Orbit = OrbitControls(THREE);
  const classes = useStyles();
  const threeRef = useRef();

  let width, height;

  // state data

  const { baseImage } = useSelector((state) => state.sites.selectedSite);
  const imageW = baseImage.width;
  const imageH = baseImage.height;

  // refs

  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();
  const sceneRef = useRef();
  const caplioRef = useRef();

  // handle window resize

  function handleWindowResize() {
    cameraRef.current.aspect =
      threeRef.current.offsetWidth / threeRef.current.offsetHeight;
    cameraRef.current.updateProjectionMatrix();
    rendererRef.current.setSize(
      threeRef.current.offsetWidth,
      threeRef.current.offsetHeight
    );
  }

  useEffect(() => {
    // === THREE.JS CODE START ===

    // size

    width = threeRef.current.offsetWidth;
    height = threeRef.current.offsetHeight;

    // marker position

    const x = position.x * baseImage.width;
    const z = position.y * baseImage.height;

    // scene

    sceneRef.current = new THREE.Scene();
    sceneRef.current.background = new THREE.Color(0xffffff);
    //sceneRef.current.fog = new THREE.FogExp2(0xffffff, 0.001);

    // renderer

    rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
    rendererRef.current.setPixelRatio(window.devicePixelRatio);
    rendererRef.current.setSize(width, height);
    threeRef.current.appendChild(rendererRef.current.domElement);

    //cameraRef.current

    cameraRef.current = new THREE.PerspectiveCamera(
      65,
      width / height,
      1,
      10000
    );
    cameraRef.current.position.set(x + 200, 200, z + 200);
    cameraRef.current.lookAt(new Vector3(x, 0, z));

    // controls

    controlsRef.current = new Orbit(
      cameraRef.current,
      rendererRef.current.domElement
    );
    //controlsRef.current.listenToKeyEvents(window);
    controlsRef.current.enableDamping = false; // an animation loop is required when either damping or auto-rotation are enabled
    //controlsRef.current.dampingFactor = 0.1;
    controlsRef.current.screenSpacePanning = false;
    controlsRef.current.minDistance = 100;
    controlsRef.current.maxDistance = 2000;
    controlsRef.current.maxPolarAngle = Math.PI / 2;
    controlsRef.current.target = new Vector3(x, 0, z);
    controlsRef.current.addEventListener("change", render);

    // baseImage
    const loader = new THREE.TextureLoader();
    loader.load(baseImage.url, (texture) => {
      let material1 = new THREE.MeshBasicMaterial({
        map: texture,
      });
      const planeGeometry = new THREE.PlaneGeometry(imageW, imageH);
      const plane = new THREE.Mesh(planeGeometry, material1);
      plane.rotation.x = -Math.PI / 2;
      plane.position.x = imageW / 2;
      plane.position.z = imageH / 2;
      //plane.position.y = 0;
      sceneRef.current.add(plane);
      render();
    });

    // soldier
    const glTFloader = new GLTFLoader();
    glTFloader.load(soldier, (gltf) => {
      caplioRef.current = gltf.scene;
      caplioRef.current.scale.multiplyScalar(50);
      caplioRef.current.position.x = x;
      caplioRef.current.position.z = z;
      sceneRef.current.add(caplioRef.current);
      render();
    });

    // lights

    const dirLight1 = new THREE.DirectionalLight(0xffffff);
    dirLight1.position.set(1, 1, 1);
    sceneRef.current.add(dirLight1);

    const dirLight2 = new THREE.DirectionalLight(0x002288);
    dirLight2.position.set(1, -1, -1);
    sceneRef.current.add(dirLight2);

    const ambientLight = new THREE.AmbientLight(0x222222);
    sceneRef.current.add(ambientLight);

    // helpers
    const axesHelper = new THREE.AxesHelper(500);
    sceneRef.current.add(axesHelper);

    // animation

    function render() {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }

    var animate = function () {
      requestAnimationFrame(animate);
      controlsRef.current.update();
      render();
    };
    // animate();
    render();

    // === THREE.JS EXAMPLE CODE END ===

    // add window resize listener
    window.addEventListener("resize", handleWindowResize);

    // clean when unmounting component
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // position change

  useEffect(() => {
    const x = position.x * baseImage.width;
    const z = position.y * baseImage.height;
    if (
      cameraRef.current &&
      controlsRef.current &&
      rendererRef.current &&
      caplioRef.current
    ) {
      // update camera position

      cameraRef.current.position.set(x + 200, 200, z + 200);
      cameraRef.current.lookAt(new Vector3(x, 0, z));

      // update controls focus

      controlsRef.current.target = new Vector3(x, 0, z);

      // update caplio position
      caplioRef.current.position.x = x;
      caplioRef.current.position.z = z;

      //render
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }
  }, [position.x, position.y]);

  return <div className={classes.modelContainer} ref={threeRef} />;
}
