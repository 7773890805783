import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import logo from "Assets/logo300.png";

import L from "leaflet";

import { makeStyles, useTheme } from "@material-ui/core";
import "./map.css";

import { addMarker } from "Features/markers/markersSlice";
import { updateLayerableIds } from "Features/layers/layersSlice";

import { layerableLayersIds, transformLayer2BaseOnLayer1 } from "Utils";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    background: "white",
  },
  targetCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    cursor: "crosshair",
  },
}));

export default function Map({
  url = logo,
  imageWidth = 1970,
  imageHeight = 1624,
  reference = true,
  referenceLayerId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  //redux & states
  const dispatch = useDispatch();
  const selectedMarkerId = useSelector(
    (state) => state.markers.selectedMarkerId
  );
  const markers = useSelector((state) => state.markers.items);
  const selectedLayerId = useSelector((state) => state.layers.selectedLayerId);
  const layers = useSelector((state) => state.layers.items);
  const transformedReferenceLayer = useSelector(
    (state) => state.layers.transformedReferenceLayer
  );

  // compute target Text

  const markersIds = markers.map(({ id }) => parseInt(id));
  const nextMarkerId = (markersIds.pop() + 1).toString();
  const selectedMarker = markers.find(
    (marker) => marker.id === selectedMarkerId
  );
  const selectedMarkerInSelectedLayer = selectedMarker?.positions?.find(
    ({ layerId }) => layerId === selectedLayerId
  );
  let targetText;
  if (!selectedMarkerId) {
    targetText = `Créer le repère ${nextMarkerId}`;
  } else if (selectedMarkerInSelectedLayer) {
    targetText = `Déplacer le repère ${selectedMarkerId}`;
  } else {
    targetText = `Ajouter le repère ${selectedMarkerId}`;
  }
  const createdMarkerId = selectedMarkerId ? selectedMarkerId : nextMarkerId;

  // Ref for programming outside react
  const mapContainerRef = useRef(); // div
  const mapRef = useRef(); // leaflet map
  const canvasRef = useRef(); // canvas with target
  const mapSize = useRef(); // map container size
  const referenceOverlay = useRef(); // Leaflet layer

  function drawLine(context, x1, y1, x2, y2) {
    context.beginPath();
    context.strokeStyle = theme.palette.primary.main;
    context.lineWidth = 1;
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
    context.closePath();
  }

  function updateMarkers(e) {
    e.originalEvent.stopPropagation();
    const coord = e.latlng;
    const x = coord.lng / imageWidth;
    const y = 1 - coord.lat / imageHeight;
    dispatch(
      addMarker({ markerId: createdMarkerId, layerId: selectedLayerId, x, y })
    );
  }

  function _plotMarker(lat, lng, text) {
    const pos = new L.LatLng(lat, lng);
    const icon = L.divIcon({
      iconSize: null,
      html: `<div class="map-custom-marker"><div class="map-custom-marker-content">${text}</div><div class="map-custom-marker-arrow"/></div>`,
    });
    L.marker(pos, { icon }).addTo(mapRef.current);
  }
  function printMarkers() {
    markers.forEach(({ id, positions }) => {
      positions.forEach(({ layerId, x, y }) => {
        if (layerId === selectedLayerId) {
          _plotMarker((1 - y) * imageHeight, x * imageWidth, id);
        }
      });
    });
  }

  // compute layerableIds
  useEffect(() => {
    const layerableIds = layerableLayersIds(referenceLayerId, markers);
    dispatch(updateLayerableIds(layerableIds));
  }, [markers]);

  // mapContainer (size & resizing)
  useEffect(() => {
    const mapContainer = mapContainerRef.current;
    function handleWindowResize() {
      const height = mapContainer.offsetHeight;
      const width = mapContainer.offsetWidth;
      mapSize.current = { width, height };

      const canvas = canvasRef.current;
      canvas.height = mapSize.current.height;
      canvas.width = mapSize.current.width;
    }
    //console.log("use effect, resize Container");
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // canvas
  useEffect(() => {
    //canvas definition
    const canvas = canvasRef.current;
    canvas.height = mapSize.current.height;
    canvas.width = mapSize.current.width;
    const ctx = canvas.getContext("2d");

    const handleMouseOver = (e) => {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      const rect = canvasRef.current.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      drawLine(ctx, x, 0, x, canvasRef.current.height);
      drawLine(ctx, 0, y, canvasRef.current.width, y);

      // add text
      ctx.font = "bold 14px Arial";
      ctx.fillStyle = theme.palette.secondary.main;
      ctx.fillText(targetText, x + 10, y - 10);
    };
    const clearCanvas = () => {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };
    // listeners
    canvasRef.current.addEventListener("mousemove", handleMouseOver);
    canvasRef.current.addEventListener("mouseout", clearCanvas);
  }, [mapSize.current, markers, selectedMarkerId]);

  // Leaflet • display map
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -5,
        zoomSnap: 0.5,
        attributionControl: false,
        zoomControl: false,
      });
    }
    const bounds = [
      [0, 0],
      [imageHeight, imageWidth],
    ];
    //remove all layers
    mapRef.current.eachLayer((layer) => mapRef.current.removeLayer(layer));

    // add layer
    const image = L.imageOverlay(url, bounds).addTo(mapRef.current);
    mapRef.current.fitBounds(bounds);
  }, [url]);

  // Leafket • plot transformed reference layer
  useEffect(() => {
    const bounds = [
      [
        imageHeight - transformedReferenceLayer.bBoxTL[1],
        transformedReferenceLayer.bBoxTL[0],
      ],
      [
        imageHeight -
          transformedReferenceLayer.bBoxTL[1] -
          transformedReferenceLayer.height,
        transformedReferenceLayer.width + transformedReferenceLayer.bBoxTL[0],
      ],
    ];
    //remove reference layer
    if (referenceOverlay.current) {
      mapRef.current.removeLayer(referenceOverlay.current);
    }
    // add layer
    if (reference) {
      referenceOverlay.current = L.imageOverlay(
        transformedReferenceLayer.url,
        bounds,
        {}
      ).addTo(mapRef.current);
    }
  }, [
    transformedReferenceLayer,
    selectedLayerId,
    referenceLayerId,
    markers,
    reference,
  ]);

  // Leaflet • update markers
  useEffect(() => {
    // remove markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        mapRef.current.removeLayer(layer);
      }
    });

    // print markers
    printMarkers();

    // events listeners
    mapRef.current.on("click", updateMarkers);

    // remove event listeners
    return () => {
      console.log("removing listener");
      mapRef.current.off("click", updateMarkers);
    };
  }, [selectedLayerId, markers, selectedMarkerId]);

  return (
    <div className={classes.mapContainer} ref={mapContainerRef}>
      <canvas className={classes.targetCanvas} ref={canvasRef} />
    </div>
  );
}
