import blueprint from "Assets/blueprint.png";
import layer1 from "Assets/layer1.png";

const layers = {
  layersId: ["blueprint", "layer1"],
  items: {
    blueprint: { url: blueprint, display: true },
    layer1: { url: layer1, display: true },
  },
};

export default layers;
