import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { blue, grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: 0,
      },
    },
  },
  palette: {
    primary: {
      main: "#BC057F", //dark pink
      fash: "#F52585",
    },
    secondary: {
      main: "#277CEA", // blue
    },
    background: {
      default: "#F4F4F8",
    },
    common: {
      borderGrey: "#E2E3E7",
      whiteBackground: grey[50],
      grey: "#e2e3e7",
      darkGrey: "#8f8f8f",
    },
  },
});

export default theme;
