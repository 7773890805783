import React from "react";
import {
  makeStyles,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import layer1 from "Assets/layer1.png";

const useStyles = makeStyles((theme) => ({
  layeringInfoContainer: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
}));

export default function LayeringInfo({
  layerable = false,
  displayReference = false,
  onSwitch = () => console.log("switch"),
}) {
  const classes = useStyles();
  const text = layerable ? "" : "Calque non superposable";

  function handleClick() {
    console.log("click");
  }

  return (
    <div className={classes.layeringInfoContainer}>
      <Typography>{text}</Typography>
      {layerable && (
        <div>
          <FormControlLabel
            value="top"
            control={
              <Switch
                color="primary"
                onChange={onSwitch}
                checked={displayReference}
                disabled={!layerable}
              />
            }
            label="Afficher le calque référence"
            labelPlacement="end"
          />
        </div>
      )}
    </div>
  );
}
