import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import LayerPreview from "./LayerPreview";

const useStyles = makeStyles((theme) => ({
  layersCollectionContainer: {
    width: "100%",
  },
}));

export default function LayersCollection({
  layers,
  selectedLayerId,
  onLayerAdded,
  onLayerDeleted,
  onLayerClick,
}) {
  const classes = useStyles();
  const referenceLayerId = useSelector(
    (state) => state.layers.referenceLayerId
  );
  return (
    <div className={classes.layersCollectionContainer}>
      {layers.map((layer) => {
        const selected = layer.id === selectedLayerId;
        const isReference = layer.id === referenceLayerId;
        return (
          <LayerPreview
            layer={layer}
            onClose={onLayerDeleted}
            key={layer.id}
            selected={selected}
            isReference={isReference}
            onClick={onLayerClick}
          />
        );
      })}
    </div>
  );
}
