import React, { useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}));

export default function FullScreenButton() {
  const classes = useStyles();
  const [fullscreen, setFullscreen] = useState(false);

  function toggleFullscreen() {
    setFullscreen((fullscreen) => !fullscreen);
  }
  return (
    <div>
      <IconButton className={classes.iconButton} onClick={toggleFullscreen}>
        {!fullscreen ? <Fullscreen /> : <FullscreenExit />}
      </IconButton>
    </div>
  );
}
