import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, Button } from "@material-ui/core";

import PageLayout from "Components/PageLayout";
import LayersManager from "./containers/LayersManager";
import Toolbar from "./containers/Toolbar";
import Markers from "./containers/Markers";
import Layer from "./containers/Layer";
import Layering from "./containers/Layering";
import ModeButton from "./containers/ModeButton";
import MapViewer from "./containers/MapViewer";

import { layerize } from "Features/layers/layersSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "stretch",
    flexWrap: "no-wrap",
  },
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    flexGrow: 1,
  },
  leftPanel: {
    minWidth: 250,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    overflowX: "hidden",
  },
  layerSection: {
    position: "relative",
    overflow: "hidden",
    flewGrow: 1,
    height: "100%",
  },
  modeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: theme.spacing(2),
    zIndex: 2000,
  },
  openMapButton: {
    position: "absolute",
    top: theme.spacing(6),
    right: 0,
    margin: theme.spacing(2),
  },
}));

export default function Superpose() {
  const classes = useStyles();
  const [mapViewerOpen, setMapViewerOpen] = useState(false);

  const layers = useSelector((state) => state.layers.items);
  const markers = useSelector((state) => state.markers.items);
  const referenceLayerId = useSelector(
    (state) => state.layers.referenceLayerId
  );

  //compute left & right panels height
  const rootRef = useRef();
  const [windowH, setWindowH] = useState(window.innerHeight);
  const [panelH, setPanelH] = useState(0);

  window.onresize = () => {
    setWindowH(window.innerHeight);
  };

  useEffect(() => {
    const rootTop = rootRef.current.offsetTop;
    setPanelH(windowH - rootTop);
  }, [windowH]);

  const dispatch = useDispatch();

  function closeMap() {
    setMapViewerOpen(false);
  }
  function openMap() {
    setMapViewerOpen(true);
  }

  useEffect(() => {
    dispatch(layerize({ layers, markers, referenceLayerId }));
  }, [layers, markers, referenceLayerId]);

  return (
    <PageLayout>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={openMap}
        className={classes.openMapButton}
      >
        Voir la carte
      </Button>
      <div className={classes.root} ref={rootRef} style={{ height: panelH }}>
        <MapViewer open={mapViewerOpen} onClose={closeMap} />
        <div className={classes.leftPanel}>
          <LayersManager />
        </div>

        <div className={classes.rightPanel}>
          <Toolbar />
          <Markers />
          <div className={classes.layerSection}>
            <Layer />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
