import grid from "Assets/grid.png";

export function distance(a, b) {
  return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
}

export function angle_abc_rad(a, b, c) {
  const ab = distance(a, b);
  const bc = distance(b, c);
  const ac = distance(a, c);
  const angle = Math.acos((bc * bc + ab * ab - ac * ac) / (2 * bc * ab));
  return angle;
}

export function layersAndmarkersFromMarkers(markers) {
  // lam = { layerId : {markerId : {x,y}, ...},...}
  const lam = {};
  markers.forEach(({ id, positions }) => {
    positions.forEach(({ layerId, x, y }) => {
      lam[layerId] = { ...lam[layerId], [id]: { x, y } };
    });
  });
  return lam;
}

export function layersAndmarkersIdsFromMarkers(markers) {
  // lamId = { layerId : [markerId,...]}
  const lamId = {};
  markers.forEach(({ id, positions }) => {
    positions.forEach(({ layerId, x, y }) => {
      if (lamId[layerId]) {
        lamId[layerId] = [...lamId[layerId], id];
      } else {
        lamId[layerId] = [id];
      }
    });
  });
  return lamId;
}

export function intersect(a, b) {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set([...setA].filter((x) => setB.has(x)));
  return Array.from(intersection);
}

export function getPointsForLayeringComputation(layer1Id, layer2Id, markers) {
  const lamId = layersAndmarkersIdsFromMarkers(markers);
  const lam = layersAndmarkersFromMarkers(markers);
  const sharedMarkersIds = intersect(lamId[layer1Id], lamId[layer2Id]);
  if (sharedMarkersIds.length >= 2) {
    const mA_id = sharedMarkersIds[0];
    const mB_id = sharedMarkersIds[1];
    const mA1 = lam[layer1Id][mA_id];
    const mB1 = lam[layer1Id][mB_id];
    const mA2 = lam[layer2Id][mA_id];
    const mB2 = lam[layer2Id][mB_id];
    return { mA1, mB1, mA2, mB2 };
  }
}

export function layerableLayersIds(referenceLayerId, markers) {
  const layerableIds = [];
  const lamId = layersAndmarkersIdsFromMarkers(markers);
  const referenceMarkersIds = lamId[referenceLayerId];
  Object.keys(lamId).forEach((layerId) => {
    if (intersect(lamId[layerId], referenceMarkersIds).length >= 2) {
      layerableIds.push(layerId);
    }
  });
  return layerableIds;
}

export function angleToVerticalAB_deg(a, b) {
  if (a.y === b.y) {
    return 90;
  } else if (a.x === b.x) {
    return 0;
  } else {
    const c = { x: a.x, y: b.y };
    const angle = (angle_abc_rad(c, a, b) * 180) / Math.PI;
    return angle;
  }
}

export async function drawImage(
  ctx,
  url,
  x,
  y,
  scale,
  rotationCenterX,
  rotationCenterY,
  rotation_deg
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      const rotation = (rotation_deg * Math.PI) / 180;
      ctx.translate(x, y);
      ctx.rotate(rotation);
      ctx.scale(scale, scale);

      ctx.drawImage(img, -rotationCenterX, -rotationCenterY);
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      resolve("ok");
    };
  });
}

// get bounding box coordinates after scaling & rotating around x,y in one image referential
function transformedBoudingBox(width, height, x, y, scale, angle_deg) {
  const angle = (angle_deg * Math.PI) / 180;
  const OA = scale * Math.sqrt(x * x + y * y);
  const OB = scale * Math.sqrt((width - x) * (width - x) + y * y);
  const OC =
    scale * Math.sqrt((width - x) * (width - x) + (height - y) * (height - y));
  const OD = scale * Math.sqrt(x * x + (height - y) * (height - y));
  const A = {};
  const B = {};
  const C = {};
  const D = {};

  A.x = x - OA * Math.sin(Math.atan(x / y) - angle);
  A.y = y - OA * Math.cos(Math.atan(x / y) - angle);
  B.x = x + OB * Math.cos(Math.atan(y / (width - x)) - angle);
  B.y = y - OB * Math.sin(Math.atan(y / (width - x)) - angle);
  C.x = x + OC * Math.cos(Math.atan((height - y) / (width - x)) + angle);
  C.y = y + OC * Math.sin(Math.atan((height - y) / (width - x)) + angle);
  D.x = x - OD * Math.sin(Math.atan(x / (height - y)) + angle);
  D.y = y + OD * Math.cos(Math.atan(x / (height - y)) + angle);

  const TL = [Math.min(A.x, B.x, C.x, D.x), Math.min(A.y, B.y, C.y, D.y)]; // Top Left
  const BR = [Math.max(A.x, B.x, C.x, D.x), Math.max(A.y, B.y, C.y, D.y)]; // Bottom Right

  return [TL, BR];
}

export async function transformLayer2BaseOnLayer1(layer1, layer2, markers) {
  // canvas & ctx
  const canvas = document.createElement("canvas");
  canvas.width = layer1.width;
  canvas.height = layer1.height;
  const ctx = canvas.getContext("2d");

  // transformation - init
  const { mA1, mB1, mA2, mB2 } = getPointsForLayeringComputation(
    layer1.id,
    layer2.id,
    markers
  );
  // rotation
  const MA2 = { x: mA2.x * layer2.width, y: mA2.y * layer2.height };
  const MB2 = { x: mB2.x * layer2.width, y: mB2.y * layer2.height };
  const MA1 = { x: mA1.x * layer1.width, y: mA1.y * layer1.height };
  const MB1 = { x: mB1.x * layer1.width, y: mB1.y * layer1.height };
  const rotation_deg =
    angleToVerticalAB_deg(MA2, MB2) - angleToVerticalAB_deg(MA1, MB1);

  // scale
  const scale = distance(MA1, MB1) / distance(MA2, MB2);
  //const scale = 1;

  // bouding box
  const [[TLx, TLy], [BRx, BRy]] = transformedBoudingBox(
    layer2.width,
    layer2.height,
    mA2.x * layer2.width,
    mA2.y * layer2.height,
    scale,
    rotation_deg
  );
  const TLx1 = TLx + mA1.x * layer1.width - mA2.x * layer2.width;
  const TLy1 = TLy + mA1.y * layer1.height - mA2.y * layer2.height;
  const BRx1 = BRx + mA1.x * layer1.width - mA2.x * layer2.width;
  const BRy1 = BRy + mA1.y * layer1.height - mA2.y * layer2.height;

  const fullBBox = [
    [Math.min(0, TLx1), Math.min(0, TLy1)],
    [Math.max(layer1.width, BRx1), Math.max(layer1.height, BRy1)],
  ];

  // update canvas dimension
  const canvasW = fullBBox[1][0] - fullBBox[0][0];
  const canvasH = fullBBox[1][1] - fullBBox[0][1];
  canvas.width = canvasW;
  canvas.height = canvasH;

  // draw
  await drawImage(
    ctx,
    layer2.url,
    mA1.x * layer1.width - fullBBox[0][0], // plot image in fullBBox in ref 1.
    mA1.y * layer1.height - fullBBox[0][1],
    scale,
    mA2.x * layer2.width,
    mA2.y * layer2.height,
    rotation_deg
  );

  // save url
  const url = canvas.toDataURL();

  return { url, width: canvasW, height: canvasH, bBoxTL: fullBBox[0] };
}

export function localTransformLayer2BaseOnLayer1(layer1, layer2, markers) {
  // return : for layer 2, scale, rotation_deg and fullBBox

  // transformation - init
  const { mA1, mB1, mA2, mB2 } = getPointsForLayeringComputation(
    layer1.id,
    layer2.id,
    markers
  );

  // rotation
  const MA2 = { x: mA2.x * layer2.width, y: mA2.y * layer2.height };
  const MB2 = { x: mB2.x * layer2.width, y: mB2.y * layer2.height };
  const MA1 = { x: mA1.x * layer1.width, y: mA1.y * layer1.height };
  const MB1 = { x: mB1.x * layer1.width, y: mB1.y * layer1.height };
  const rotation_deg =
    angleToVerticalAB_deg(MA2, MB2) - angleToVerticalAB_deg(MA1, MB1);

  // scale
  const scale = distance(MA1, MB1) / distance(MA2, MB2);
  //const scale = 1;

  // bouding box
  const [[TLx, TLy], [BRx, BRy]] = transformedBoudingBox(
    layer2.width,
    layer2.height,
    mA2.x * layer2.width,
    mA2.y * layer2.height,
    scale,
    rotation_deg
  );
  const TLx1 = TLx + mA1.x * layer1.width - mA2.x * layer2.width;
  const TLy1 = TLy + mA1.y * layer1.height - mA2.y * layer2.height;
  const BRx1 = BRx + mA1.x * layer1.width - mA2.x * layer2.width;
  const BRy1 = BRy + mA1.y * layer1.height - mA2.y * layer2.height;

  const fullBBox = [
    [Math.min(0, TLx1), Math.min(0, TLy1)],
    [Math.max(layer1.width, BRx1), Math.max(layer1.height, BRy1)],
  ];

  return { MA1, MA2, scale, rotation_deg, fullBBox };
}

export async function transformLayer(
  ctx,
  layer,
  MA1,
  MA2,
  scale,
  rotation_deg,
  fullBBox
) {
  // draw
  await drawImage(
    ctx,
    layer.url,
    MA1.x - fullBBox[0][0], // plot image in fullBBox in ref 1.
    MA1.y - fullBBox[0][1],
    scale,
    MA2.x,
    MA2.y,
    rotation_deg
  );
}

function updateFullBBox(fullBBox, newBBox) {
  const TLX = Math.min(fullBBox[0][0], newBBox[0][0]);
  const TLY = Math.min(fullBBox[0][1], newBBox[0][1]);
  const BRX = Math.max(fullBBox[1][0], newBBox[1][0]);
  const BRY = Math.max(fullBBox[1][1], newBBox[1][1]);
  return [
    [TLX, TLY],
    [BRX, BRY],
  ];
}
export async function transformedLayersBaseOnReferenceLayer(
  layers,
  markers,
  referenceLayerId
) {
  const referenceLayer = layers.find((layer) => layer.id === referenceLayerId);
  let bBox = [
    [0, 0],
    [referenceLayer.width, referenceLayer.height],
  ];

  let transformedLayers = {};
  let transformedLayersIds = [];

  const localTransformedLayers = layers.map((layer) => {
    const {
      MA1,
      MA2,
      scale,
      rotation_deg,
      fullBBox,
    } = localTransformLayer2BaseOnLayer1(referenceLayer, layer, markers);
    bBox = updateFullBBox(bBox, fullBBox);
    return { layer, MA1, MA2, scale, rotation_deg };
  });

  //plot & export
  await Promise.all(
    localTransformedLayers.map(
      async ({ layer, MA1, MA2, scale, rotation_deg }) => {
        // we create one canvas for each layer (otherwise, we get layer superposition)
        const canvas = document.createElement("canvas");
        canvas.width = bBox[1][0] - bBox[0][0];
        canvas.height = bBox[1][1] - bBox[0][1];
        const ctx = canvas.getContext("2d");
        // draw transformed layer
        await transformLayer(ctx, layer, MA1, MA2, scale, rotation_deg, bBox);
        // compute url
        const url = canvas.toDataURL();
        //update transformedLayers
        transformedLayersIds.push(layer.id);
        transformedLayers[layer.id] = {
          url,
          width: canvas.width,
          height: canvas.height,
          display: true,
        };
      }
    )
  );

  return { ids: transformedLayersIds, items: transformedLayers, bounds: bBox };
}
