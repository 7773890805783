import React, { useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fileUploaderContainer: {
    width: "100%",
    height: 200,
    background: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "relative",
  },
  fileInput: {
    opacity: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  draggableContainer: {
    width: "80%",
    margin: theme.spacing(2),
    border: `2px dashed ${theme.palette.primary.main}`,
    flexGrow: 1,
  },
  focusBorder: {
    borderColor: theme.palette.secondary.main,
  },
  browseButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  helperTextContainer: {
    color: theme.palette.common.grey,
    marginTop: theme.spacing(2),
  },
  blobPreviewContainer: {
    width: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
}));

export default function FileUploader({ onFileUpload }) {
  const classes = useStyles();
  const fileInputRef = useRef();

  const [onDragOver, setOnDragOver] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);

  function _uploadFile(file) {
    setFileLoaded(true);
    const localUrl = window.URL.createObjectURL(file);
    onFileUpload(localUrl);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setOnDragOver(true);
  }
  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setOnDragOver(false);
  }
  async function handleDrop(e) {
    e.preventDefault();
    setOnDragOver(false);
    const file = e.dataTransfer.items[0].getAsFile();
    _uploadFile(file);
  }
  function handleChange(e) {
    const file = e.currentTarget.files[0];
    _uploadFile(file);
  }

  return (
    <div className={classes.fileUploaderContainer}>
      <input
        className={classes.fileInput}
        type="file"
        ref={fileInputRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onChange={handleChange}
      />
      <div
        className={clsx(
          classes.draggableContainer,
          onDragOver && classes.focusBorder
        )}
      >
        <div className={classes.helperTextContainer}>
          <Typography align="center" color="inherit">
            Déposer une image ici
          </Typography>
        </div>
      </div>
      <div className={classes.browseButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
        >
          Choisir une image
        </Button>
      </div>
    </div>
  );
}
