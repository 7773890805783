import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

import superposeLogo from "./images/superpose.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: theme.spacing(1),
    alignItems: "bottom",
  },
  imageContainer: {
    marginRight: theme.spacing(1),
  },
}));

export default function ToolLogo({ toolName }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img src={superposeLogo} height={30} />
      </div>
      <Typography>{toolName}</Typography>
    </div>
  );
}
