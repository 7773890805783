import React, { useRef, useEffect } from "react";

import { makeStyles, Typography } from "@material-ui/core";

import L from "leaflet";

import MapName from "./components/MapName";

import LayersSelector from "./components/LayersSelector";

import defaultMap from "Data/defaultMap.js";
import FullScreenButton from "./components/FullScreenButton";

const useStyles = makeStyles((theme) => ({
  mapViewerContainer: {
    width: "100%",
    height: "100%",
  },
  mapContainer: {
    width: "100%",
    height: "100%",
    background: "white",
  },
  mapNameContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: theme.spacing(1),
    zIndex: 10,
  },
  layersSelectorContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    margin: theme.spacing(2),
    marginTop: theme.spacing(6),
    zIndex: 1000,
  },
  fullScreenButtonContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 10,
    margin: theme.spacing(3),
  },
}));

export default function MapViewer({ map = defaultMap }) {
  const classes = useStyles();

  const mapContainerRef = useRef();
  const mapRef = useRef();

  const { layers, name } = map;

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -5,
        zoomSnap: 0.5,
        attributionControl: false,
        zoomControl: false,
      });
    }

    // plot layer
    function plotLayer({ url, width, height }) {
      const bounds = [
        [0, 0],
        [height, width],
      ];
      const image = L.imageOverlay(url, bounds).addTo(mapRef.current);
      mapRef.current.fitBounds(bounds);
    }

    //plot layers
    mapRef.current.eachLayer((layer) => mapRef.current.removeLayer(layer));
    layers.ids.forEach((layerId) => {
      if (layers.items[layerId].display) {
        plotLayer(layers.items[layerId]);
      }
    });

    //plot boundingBox
    const bBox = layers.bounds;
    const bounds = [
      [0, 0],
      [bBox[1][1] - bBox[0][1], bBox[1][0] - bBox[0][0]],
    ];
    console.log(bounds);
    L.rectangle(bounds, {
      color: "black",
      weight: 1,
      dashArray: "8",
      fill: false,
    }).addTo(mapRef.current);
  }, [layers]);

  return (
    <div className={classes.mapViewerContainer}>
      <div className={classes.mapNameContainer}>
        <MapName name={name} />
      </div>
      <div className={classes.layersSelectorContainer}>
        <LayersSelector layers={layers} />
      </div>
      <div className={classes.fullScreenButtonContainer}>
        <FullScreenButton />
      </div>
      <div ref={mapContainerRef} className={classes.mapContainer} />
    </div>
  );
}
