import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import L from "leaflet";
import "leaflet.markercluster";

import "./mapViewer.css";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "100%",
  },
}));

export default function MapViewer() {
  //style
  const classes = useStyles();
  // state data
  const maps = useSelector((state) => state.maps.items);
  const selectedMapId = useSelector((state) => state.maps.selectedMapId);
  const selectedMap = maps[selectedMapId];
  const objects = useSelector((state) => state.objects);
  console.log("objects", objects);

  // leaflet map
  const mapContainerRef = useRef();
  const mapRef = useRef();
  useEffect(() => {
    // initial map
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -5,
        maxZoom: 16,
        zoomSnap: 1,
        attributionControl: false,
        zoomControl: false,
      });
    }
    const { url, height, width } = selectedMap;
    const bounds = [
      [0, 0],
      [height, width],
    ];
    const image = L.imageOverlay(url, bounds).addTo(mapRef.current);
    mapRef.current.fitBounds(bounds);

    // objects markers
    function _plotMarker(x, y, url, imgWidth, imgHeight) {
      const icon = new L.DivIcon({
        iconSize: null,
        html: `<div class="custom-marker-icon"> 
        <div style="background-color: white; background-size: cover; 
        background-repeat: no-repeat;
        background-position: center center; 
        width: 100%;
        height: 100%;
        background-image: url(${url});
        "
        </div>`,
      });
      L.marker([y * imgHeight, x * imgWidth], { icon }).addTo(mapRef.current);
    }
    function _createMarker(x, y, url, imgWidth, imgHeight) {
      const icon = new L.DivIcon({
        iconSize: null,
        html: `<div class="custom-marker-icon"> 
          <div style="background-color: white; background-size: cover; 
          background-repeat: no-repeat;
          background-position: center center; 
          width: 100%;
          height: 100%;
          background-image: url(${url});
          "
          </div>`,
      });
      return L.marker([y * imgHeight, x * imgWidth], { icon });
    }

    const imgUrl = objects.items["poutre"].url;
    const x = 0.5;
    const y = 0.5;
    const imgWidth = width;
    const imgHeight = height;
    _plotMarker(x, y, imgUrl, imgWidth, imgHeight);

    //for clustering
    const markersCluster = new L.MarkerClusterGroup();
    //random plot
    const randomX = [...new Array(1000)].map(() => Math.random());
    const randomY = [...new Array(1000)].map(() => Math.random());
    randomX.forEach((x, index) => {
      //_plotMarker(x, randomY[index], imgUrl, imgWidth, imgHeight);
      const marker = _createMarker(
        x,
        randomY[index],
        imgUrl,
        imgWidth,
        imgHeight
      );
      markersCluster.addLayer(marker);
    });
    mapRef.current.addLayer(markersCluster);
  }, [selectedMap]);

  return <div className={classes.mapContainer} ref={mapContainerRef}></div>;
}
