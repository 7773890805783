import { createSlice } from "@reduxjs/toolkit";

import planMasse from "Assets/planMasse.png";

export const mapsSlice = createSlice({
  name: "maps",
  initialState: {
    ids: ["masse"],
    items: {
      masse: { url: planMasse, width: 7087, height: 3307 },
    },
    selectedMapId: "masse",
  },
  reducers: {
    toggleMode: (state) => {},
  },
});

export const { toggleMode } = mapsSlice.actions;

export default mapsSlice.reducer;
