import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { switchTransformedLayerDisplay } from "Features/layers/layersSlice";

import { List, ListItem, IconButton, Paper, Switch } from "@material-ui/core";
import { Layers, LayersClear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import ImagePreview from "Components/ImagePreview";

import defaultLayers from "Data/defaultLayers";

const useStyles = makeStyles((theme) => ({
  root: { position: "relative" },
  panel: { position: "absolute", top: 0, right: 0 },
  layersHeader: { display: "flex", justifyContent: "flex-end" },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  closeButton: {
    color: theme.palette.primary.main,
  },
  openButtonContainer: {
    padding: theme.spacing(1),
  },
  layersContainer: {
    width: theme.spacing(24),
    padding: theme.spacing(1),
    background: theme.palette.background.default,
  },
  layerPreviewContainer: {
    background: "white",
    width: "100%",
  },
}));

function LayerPreview({ id, url, display = false, onSwitch }) {
  const classes = useStyles();

  function handleSwitch() {
    onSwitch(id);
  }
  return (
    <div className={classes.layerPreviewContainer}>
      <ImagePreview src={url} width={"100%"} height={150} />
      <Switch
        onChange={handleSwitch}
        checked={display}
        size="small"
        color="primary"
      />
    </div>
  );
}
export default function LayersSelector({ layers = defaultLayers }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  function toggleOpen() {
    setOpen((open) => !open);
  }

  function onSwitch(layerId) {
    dispatch(switchTransformedLayerDisplay(layerId));
  }
  return (
    <div className={classes.root}>
      {!open ? (
        <div className={classes.panel}>
          <div className={classes.openButtonContainer}>
            <IconButton className={classes.iconButton} onClick={toggleOpen}>
              <Layers color="inherit" />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className={classes.panel}>
          <Paper elevation={6} className={classes.layersContainer}>
            <div className={classes.layersHeader}>
              <IconButton className={classes.closeButton} onClick={toggleOpen}>
                <LayersClear color="inherit" />
              </IconButton>
            </div>

            <List disablePadding>
              {layers.ids.map((layerId) => {
                return (
                  <ListItem disableGutters key={layerId}>
                    <LayerPreview
                      url={layers.items[layerId].url}
                      onSwitch={() => onSwitch(layerId)}
                      display={layers.items[layerId].display}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>
      )}
    </div>
  );
}
