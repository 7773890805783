import NavBar from "./NavBar";
import Path from "./Path";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const PageLayout = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.main}>
        <Path />
        {props.children}
      </div>
    </div>
  );
};
export default PageLayout;
