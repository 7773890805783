import { AppBar, Toolbar, Link, Typography } from "@material-ui/core";
import WelcomeName from "./WelcomeName";
import Logo from "./Logo";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  toolBar: {
    backgroundColor: "transparent",
  },
  appbar: {
    backgroundColor: "transparent",
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={0} position="static">
        <Toolbar disableGutters className={classes.toolBar} variant="dense">
          <Logo />
          <Typography className={classes.title}>
            <Link component={RouterLink} to="/" variant="h6">
              Map
            </Link>
          </Typography>
          <WelcomeName />
          {/* <SignInSignOutButton /> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
