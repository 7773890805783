import React from "react";
import { Button, Typography } from "@material-ui/core";

export default function ModeButton({ mode3d, onClick }) {
  const text = mode3d ? "Voir en 2D" : "Voir en 3D";
  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        <Typography>{text}</Typography>
      </Button>
    </div>
  );
}
