import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { getPointsForLayeringComputation } from "Utils";

import LayeringCanvas from "./components/LayeringCanvas";

const useStyles = makeStyles((theme) => ({
  layeringContainer: {
    background: "white",
    height: 500,
    position: "relative",
  },
}));

export default function Layering() {
  const classes = useStyles();
  const markers = useSelector((state) => state.markers.items);

  const points = getPointsForLayeringComputation("layer1", "layer2", markers);
  console.log(points);
  return (
    <div className={classes.layeringContainer}>
      <LayeringCanvas />
    </div>
  );
}
