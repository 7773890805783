import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateTransformedReferenceLayer } from "Features/layers/layersSlice";

import { makeStyles } from "@material-ui/core";

import Map from "Components/Map";
import MovingTargetLayer from "Components/MovingTargetLayer";
import LayeringInfo from "./components/LayeringInfo";

const useStyles = makeStyles((theme) => ({
  layerContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  layerMapContainer: {
    flexGrow: 1,
  },
  layeringInfoContainer: {
    margin: theme.spacing(0),
  },
}));

export default function Layer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [reference, setReference] = useState(true);
  const [mapHeight, setMapHeight] = useState(0);

  const selectedLayerId = useSelector((state) => state.layers.selectedLayerId);
  const referenceLayerId = useSelector(
    (state) => state.layers.referenceLayerId
  );
  const layers = useSelector((state) => state.layers.items);
  const markers = useSelector((state) => state.markers.items);
  const layer = layers.find((layer) => layer.id === selectedLayerId);
  const layerableIds = useSelector((state) => state.layers.layerableIds);
  const layerable = layerableIds.includes(selectedLayerId);

  const referenceLayer = layers.find(
    (layers) => layers.id === referenceLayerId
  );

  function toggleReference() {
    setReference((reference) => !reference);
  }

  useEffect(() => {
    dispatch(
      updateTransformedReferenceLayer({
        layer1: layer,
        layer2: referenceLayer,
        markers,
      })
    );
  }, [referenceLayerId, layer, markers]);

  //compute map Height
  const layerMapContainerRef = useRef();

  useEffect(() => {
    const height = layerMapContainerRef.current.offsetHeight;
    setMapHeight(height);
  }, [layerMapContainerRef.current]);

  return (
    <div className={classes.layerContainer}>
      <div ref={layerMapContainerRef} className={classes.layerMapContainer}>
        {mapHeight > 0 && (
          <Map
            url={layer?.url}
            imageWidth={layer?.width}
            imageHeight={layer?.height}
            referenceLayerId={referenceLayerId}
            reference={reference}
            mapHeight={mapHeight}
          />
        )}
      </div>
      <div className={classes.layeringInfoContainer}>
        <LayeringInfo
          className={classes.layeringInfo}
          layerable={layerable}
          onSwitch={toggleReference}
          displayReference={reference}
        />
      </div>
    </div>
  );
}
