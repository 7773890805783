import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  layerContainer: {
    width: 300,
    height: 300,
    background: theme.palette.common.grey,
    opacity: 0.5,
  },
}));

export default function MovingTargetLayer() {
  const classes = useStyles();
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    console.log("canvas ", canvas);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "green";
    ctx.fillRect(10, 10, 100, 100);
  }, []);

  return (
    <div className={classes.layerContainer}>
      <canvas ref={canvasRef} />
    </div>
  );
}
